.swiper{
    // background-color: pink!important;
    padding: 0 6% !important;
    
    &-slide{
        // background-color: plum;
        // transition: opacity .3s ease;
        transition-property: transform opacity!important;
        
        .img-container{
            border-radius: .25rem;
            border: var(--border);
            overflow: auto;
            
            width: 100%;
            height: 400px;
        }
        
        ion-img{
            object-fit: contain;
            object-position: center;
            width: 100%;
            height: 100%;
        }

        .file, .img-container{
            transition: opacity .5s ease;
        }

        &-next .file, &-prev .file, &-next .img-container, &-prev .img-container{
            opacity: .5;
        }
    }
}

.slider-file{
    margin-bottom: .5rem;
}