.home{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    height: calc(100% - 5rem);
    overflow: auto;
    padding: var(--padding);

    @media screen and ( max-width : 425px ) {
        height: 100%;
        justify-content: flex-start;
    }

    &__greeting{
        font-size: 2.5rem;
        text-align: center;
        color: var(--bs-primary);

        @media screen and ( max-width : 425px ) {
            font-size: 2rem;
        }

        & span{
            display: block;
            font-size: 1.75rem;
            margin-top: .5rem;
            color: var(--bs-primary-medium);

            @media screen and ( max-width : 425px ) {
                font-size: 1.25rem;
            }
        }
    }
    
    &__ul{
        list-style: none;
        margin: 0;
        padding: 0;
        
        display: -ms-grid;
        display: grid;
        grid-template-columns: repeat( 3, 1fr );
        text-align: center;
        gap: 2rem;

        @media screen and ( max-width : 1360px ) {
            grid-template-columns: repeat( 2, 1fr );
        }

        @media screen and ( max-width : 650px ) {
            gap: 1rem;
            width: 100%;
        }

        @media screen and ( max-width : 425px ) {
            grid-template-columns: repeat( 1, 1fr );
        }
    }

    &__li{
        width: 100%;
    }

    ion-item{
        --padding-start: 0;
        --inner-padding-end: 0;
        --background-focused-opacity: 0;
        --background-hover-opacity: 0;
    }
    
    &__a{
        color: var(--bs-primary);
        min-width: 275px;

        display: flex;
        flex-direction: column;

        width: 100%;
        height: 100%;
        
        border: var(--border);
        border-color: var(--c-border);
        border-radius: .5rem;

        .exit & {
            color: var(--bs-secondary);
            border-color: var(--bs-secondary-t-60);
        }

        & span{
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            
            width: 100%;
            padding: 1rem;
            flex-grow: 1;

            border-radius: .5rem .5rem 0 0;
            background-color: var(--bs-primary-selected);
            border-bottom: var(--border);
            border-color: var(--c-border);

            -webkit-transition: background-color .2s ease;
            -o-transition: background-color .2s ease;
            transition: background-color .2s ease;

            .exit & {
                background-color: var(--bs-secondary-t-015);
                border-color: var(--bs-secondary-t-60);
            }
        }

        &:hover span{
            background-color: var(--bs-primary-selected-hover);

            .exit &{
                background-color: var(--bs-secondary-t-025);
            }
        }

        & ion-icon{
            width: 1.25em;
            height: 1.25em;
            padding: .5em;
        
            display: -webkit-box;
        
            display: -ms-flexbox;
        
            display: flex;
            -webkit-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
        
            border-radius: 50%;
        
            font-size: 2rem;
            background-color: white;
            border: var(--border);
            border-color: var(--c-border);

            .exit & {
                border-color: var(--bs-secondary-t-60);
            }

            @media screen and ( max-width : 650px ) {
                width: 0.75em;
                height: 0.75em;
                padding: 0.25em;
            }
        }

        @media screen and ( max-width : 650px ) {
            min-width: 0;
            width: 100%;
        }
    }

    &__title{
        text-align: center;
        font-size: 1.25rem;

        @media screen and ( max-width : 650px ) {
            font-size: 1rem;
        }
    }
}