.report{
    width: 100%;
    padding: .75rem;
    border-radius: .5rem;
    background-color: white;
    border: 1px solid var(--c-border);
    box-shadow: 0 0 10px hsla(190, 49%, 33%, 0.025);

    width: 100%;
    box-shadow: 0 0 10px hsla(190, 49%, 33%, 0.025);
}

.report ion-icon{
    pointer-events: none;
}

.report__title{
    font-size: 1.15rem;
    padding: 0 0 .65rem .25rem;
    margin-bottom: .75rem;
    border-bottom: 1px solid var(--c-border);
    font-weight: bold;

    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.report__title p{
    margin:0;
}

.report__date{
    font-size: .9rem;
    font-weight: normal;

    margin:0;
    margin-top: .15rem;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: var(--bs-primary-medium);
}

.report__date ion-icon{
    margin-right: .25rem;
}

.report__button{
    width: 2.35rem;
    height: 2.35rem;
    font-size: 1.5rem;

    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-start;

    border: var(--border);
    border-color: var(--bs-primary-medium-light);
    border-radius: .25rem;

    cursor: pointer;
    transition: background-color .3s ease;
}

.report__button:hover{
    background-color: var(--bs-primary-selected);
}

.report__prop{
    margin: .25rem;
    font-size: .95rem;
    line-height: 1.4;
}

.aptitudes{
    margin: .25rem 0;
}

.report__prop + .report__prop{
    margin-top: .75rem;
}

.report__prop--advice::before{
    content:"- ";
    margin-right:.25rem;
}

.report__prop--title{
    display: block;
    font-weight: bold;
}

.aptitudes .report__prop--title{
    margin: 0 .25rem;
}

.report__prop--value{
    display: inline;
}

.report__results{
    background-color: var(--bs-primary-selected);
    border-radius: .25rem;
    padding: .75rem;
}

.report__result{
    margin: 0;
}

.report__result span{
    display: block;
    font-weight: bold;
}

.report__prop + .file, .report__results + .report__results, .report + .report, .report__result + .report__result{
    margin-top: .75rem;
}

.report__prop--advice + .report__prop--advice{
    margin-top: 0;
}

.file{
    display: flex;
    justify-content: flex-start;
    align-items: stretch;

    padding: .5rem;
    border: var(--border);
    border-color: var(--c-border);
    border-radius: .25rem;

    transition: background-color .3s ease;
    
    color: var(--bs-primary);
}

.file:not(.no-hover){
    cursor: pointer;
}

.file:not(.no-hover):hover{
    background-color: var(--bs-primary-selected);
}

.file + .file{
    margin-top: .5rem;
}

.file__icon{
    flex-shrink: 0;

    font-size: 1.25rem;
    border-right: var(--border);
    border-color: var(--c-border);
    padding-right: .5rem;
    margin-right: .5rem;

    display: flex;
    justify-content: center;
    align-items: center;
}

.file__info{
    margin: 0;
    flex-grow: 1;
    align-self: center;

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    line-height: 1.25;
    overflow: hidden;
}

.file__type{
    color: var(--ion-text-color);
    flex-grow: 1;
    flex-shrink: 0;
    font-weight: bold;
}

.file__name{
    opacity: .75;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: .95rem;
    text-decoration: underline;
}

.file__delete{
    cursor: pointer;
}

.file__delete ion-icon{
    font-size: 1.25rem;
    vertical-align: middle;
    pointer-events: none;

    transition: all .3s ease;
}

.file__delete:hover ion-icon{
    color: var(--ion-color-danger);
}

a.file__name{
}

.report__see-more{
    /* display: block; */
    margin-left: .5rem;
    text-decoration: underline;
    /* display: block; */
    /* color: var(--bs-primary-medium); */
}

@media screen and ( max-width: 500px ){
 .file__info{
    flex-direction: column;
 }
}