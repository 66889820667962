.plots{
    padding: var(--padding);
}

.plots__title{
    font-size: 1.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-left: .25rem;
    color: var(--bs-primary);
    border-bottom: 1.5px solid var(--c-border);
}

.plots__select-button{
    font-size: 1rem;
    text-transform: uppercase;

    --background: transparent;
    color: var(--bs-primary);
    border: 1px solid var(--c-border);
    border-radius: var(--bs-border-radius);
    margin: 0 0 1rem 0;
    width: 100%;
    max-width: none;

    --box-shadow: none;
}

.plots .plots__container{
    border: var(--border);
    border-radius: 0.35rem;
    padding: 1rem;
    border-color: var(--bs-primary-light);
}

.plots .js-plotly-plot{
    width: 100%;
    /* max-width: 500px; */
}

.plots__select-button ion-icon{
    margin-left: auto;
}

.loss{
    margin: 1rem;
}

.loss__wrap{
    padding: .5rem;
    border-radius: .5rem;
    background-color: var(--bs-primary-hover);
    
    /* display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; */

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: .5rem;
}

.loss__item{
    padding: 1.25rem 1.25rem 1.25rem 1.5rem;
    /* margin-bottom: 1rem; */
    border-radius: .5rem;
    background-color: white;
    border: 1px solid var(--c-border);
    box-shadow: 0 0 10px hsla(190, 49%, 33%, 0.025);

    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    width: 100%;
}

.loss__text{
    color: var(--bs-primary);
}

.loss__value{
    font-size: 2rem;
    font-weight: 600;
    color: #222;
}

.loss__icon{
    width: 2.25rem;
    height: 2.25rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    box-shadow: 0 0 10px hsla(190, 49%, 33%, 0.025);
}

.loss__svg{
    width: 100%;
    height: 100%;
    color: var(--bs-primary);
}

@media screen and (max-width: 700px) {
    .loss__wrap{
        flex-direction: column;
    }
    .loss__item{
        margin-bottom: .75rem;
        width: 100%
    }
    .loss__item:not(:last-child){
        margin-right: 0;
    }
}
