.create-notif{
    .file-input{
        margin: 1rem 0 0;
        padding: 1rem 0;
        border-top: var(--border);
        border-bottom: var(--border);

        & .file:first-of-type{
            margin-top: .5rem;
        }
    }
}

.notif-files{
    margin-top: .75rem;

    &__li{
        font-size: .9rem;
        color: var(--bs-primary-medium);

        & + &{
            margin-top: .25rem;
        }
    }
}