
.user-profile{
    --background: var(--bs-primary-hover);
    --ion-padding: 1rem;
    border-radius: .5rem;
}

.user-profile__list{
    list-style: none;
    margin: 0;
    padding: 0;

    background-color: white;
    border: 1px solid var(--c-border);
    box-shadow: 0 0 10px hsla(190, 49%, 33%, 0.025);
    border-radius: .25rem;
    padding: 2.5rem 1.5rem 1.5rem;
}

ion-button.user-profile__close-button{
    margin-left: auto;
}

.user-profile__close{
    display: block;
    position: relative;
    font-size: 5em;
    color: var(--bs-primary);
}

.user-profile__avatar{
    width: 5rem;
    height: 5rem;
    margin: 0 auto 1rem;
}

.user-profile__note{
    display: block;
    color: inherit;
    margin:0;
    padding: 0;

    text-align: inherit;
    opacity: 1;
}

.user-profile__item--name{
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    
    color: var(--ion-text-color);
}

.user-profile__item--email, .user-profile__item--phone{
    text-align: center;
    color: var(--bs-primary-medium);
    margin-top: .25rem;
}

.user-profile__item--phone{
    padding-bottom: 1.5rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    border-bottom: 1px solid var(--c-border);
}

.user-profile__item--phone ion-icon{
    margin-right: .5rem;
}

.user-profile__item--phone ion-note{
    width: auto;
}

.user-profile__item--other .user-profile__note{
    border: 1px solid var(--c-border);
    box-shadow: 0 0 10px hsla(190, 49%, 33%, 0.025);
    border-radius: .25rem;
    background-color: var(--bs-primary-hover);

    padding: .75rem;
    margin: 0 -.2rem;
}

ion-button.user-profile__button{
    color: var(--c-white);
    text-transform: uppercase;
    max-width: none;
    padding: 0 2rem;
    height: 2.5rem;
    background-color: var(--bs-primary);
    border-radius: .5rem;

    margin: 1rem auto 1rem!important;
}
