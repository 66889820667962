ion-menu{
  /* max-width: 350px; */
  --border: 1px solid var(--c-border);
}

@media screen and (min-width: 991px) {
  ion-menu{
    max-width: 350px;
  }
}

@media screen and (max-width: 991px) {
  .menu-pane-visible{
    position: absolute;
  }
}

ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

/* ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
} */

ion-avatar{
  margin-top: 1rem;
  margin-left:1rem;

  /* box-shadow: 0 0 0 1px var(--bs-primary-light); */
}

ion-menu.md ion-list, ion-menu.ios ion-list {
  margin: 0 7%;
  padding: 1rem 0 1.25rem;
  border-bottom: 1px solid var(--c-border);
}

ion-menu ion-menu-toggle ion-icon{
  padding-left: 1rem;
}

ion-menu.ios ion-menu-toggle ion-item ion-icon, ion-menu.md ion-menu-toggle ion-item ion-icon{
  color: inherit;
}

ion-menu.md ion-note, ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 30px;
}

ion-menu.md ion-list-header, ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list#inbox-list ion-list-header {
  font-size: 22px;
  font-weight: 600;
  line-height: 0;
  margin-top: 1rem;
  min-height: 20px;
}

ion-menu.ios ion-content, ion-menu.md ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-item, ion-menu.md ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item ion-icon, ion-menu.md ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-menu-toggle:last-child ion-item ion-icon, ion-menu.ios ion-menu-toggle:last-child ion-item, 
ion-menu.md ion-menu-toggle:last-child ion-item ion-icon, ion-menu.md ion-menu-toggle:last-child ion-item{
  --color-hover: rgba(var(--ion-color-danger-rgb), 10);
  color: var(--bs-secondary);
}

ion-menu.ios ion-menu-toggle:last-child ion-item:hover ion-label, 
ion-menu.md ion-menu-toggle:last-child ion-item:hover ion-label{
  color: var(--bs-secondary);
}


ion-menu.ios ion-item .selected ion-icon, ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-list#labels-list ion-list-header, ion-menu.md ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note, ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 1rem;
  padding-right: 0;
  text-align: start;
}

ion-menu.ios ion-list-header, ion-menu.md ion-list-header{
  margin-bottom: 1rem;
  margin-top: .5rem;
  min-height: auto;

  font-size: 22px;
  font-weight: 600;
}

ion-menu.ios ion-list-header.user, ion-menu.md ion-list-header.user{
  margin-bottom: 0;
}

ion-menu.ios ion-note, ion-menu.md ion-note {
  margin-top: 0;
  margin-bottom: 8px;
}

ion-menu.ios ion-note.profile, ion-menu.md ion-note.profile{
  margin-top: .5rem;
  padding-bottom: 1.25rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid var(--c-border);

  font-size: 15px;
}

ion-note {
  display: inline-block;
  font-size: 16px;
  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
  --ion-item-background: var(--bs-primary-selected);

} 

ion-menu.ios ion-item, ion-menu.md ion-item{
  --border-color: transparent;
  --padding-start: 0;
  --padding-end: 0;
}

ion-menu.ios ion-item:last-child, ion-menu.md ion-item:last-child{
  --border-color: transparent;
}

ion-menu ion-label.menu_notif{
  padding: .5rem .75rem;
  border-radius: .5rem;
  border: 1px solid var(--c-border);
  margin: 0;

  transition: all .2s ease;
}

ion-menu ion-item:hover .menu_notif{
  border-left-width: .85rem;
}

ion-menu ion-label.menu_notif.accepted{
  border-left: .5rem solid var(--color-green);
}

ion-menu ion-label.menu_notif.denied{
 border-left: .5rem solid var(--bs-secondary);
}

ion-menu ion-label.menu_notif.pending{
 border-left: .5rem solid var(--bs-primary);
}

ion-menu.ios ion-list:last-child, ion-menu.md ion-list:last-child{
  border-bottom: none;
  padding-bottom: 0;
}

.menu_notif__type{
  text-transform: uppercase;
  font-weight: 500;
  border-bottom: 1px solid var(--c-border);
  color: var(--ion-text-color);

  display: block;
  margin-bottom: .5rem;
  padding: .25rem 0 .35rem .25rem;

  white-space: normal;
}

.menu_notif__time{
  font-size: .9rem;
  color: var(--bs-primary-medium);
  padding: 0 .25rem .25rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* .menu_notif__flag{
  display: inline-block;
  width: .75rem;
  height: 1.25rem;
  border-radius: .25rem;
  vertical-align: bottom;
  margin-right: .45rem;
}

.menu_notif__flag.accepted{
  background: var(--color-green);
}

.menu_notif__flag.denied{
  background: var(--bs-secondary);
}

.menu_notif__flag.pending{
  background: var(--bs-primary);
} */

ion-menu.ios ion-item ion-icon.menu_notif__icon, ion-menu.md ion-item ion-icon.menu_notif__icon{
  font-size: .9rem;
  color: currentColor;
  vertical-align: text-top;
  margin-right: .15rem;
  margin-top: .75px;
}

.menu-notif__list .item .sc-ion-label-ios-h{
  margin: 0!important;
}

.menu_notif__buttons{
  margin-top: 1rem;
  /* margin-top: 1rem; */
}

.menu_notif__button{
  padding: .5rem;
  border-radius: .5rem;
  text-align: center;
  /* width: 100%; */
  /* display: block; */

  font-size: .85rem;
}

.menu_notif__button.accepted{
  background: var(--color-green);
  color: white;

}

.menu_notif__button.denied{
  background: var(--bs-secondary);
  color: white;

}

.menu_notif__button.pending{
  background: var(--bs-primary);
  color: white;
}

.menu_notif__list ion-item{
  --inner-padding-start: 0px;
  --inner-padding-end: 0px;
  margin-inline: .4rem;
  margin-bottom: .75rem;
}

.center-alert{
  margin: 1rem 7% 0;
  padding: 1.25rem 1rem;
  text-align: center;
  border: 1px solid var(--bs-primary-t-025);
  border-radius: .25rem;
  background-color: var(--bs-primary-selected);
}

.center-alert__question p{
  margin-top: 0;
}

.center-alert.confirmed .center-alert__question{
  display: none;
}

.center-alert__confirmed{
  display: none;
}

.center-alert.confirmed .center-alert__confirmed{
  display: block;
  margin: 0;
}