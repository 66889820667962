.request__title{
    background-color: var(--bs-primary-selected);
    padding: 1rem;
    border-radius: .5rem;
}

.request__input{
    appearance: none;
    border: 1px solid var(--c-border);
    border-radius: .5rem;
    padding: 1rem;
    height: 3rem;
    background-color: var(--c-white);
    width: 100%;

    margin: 1rem 0;

    box-shadow: 0 10px 10px hsla(190, 49%, 33%, 0.025);
}

ion-label + .request__input{
    margin-top: 0;
}

ion-button.modal-request__req{
    --background: transparent;
    --box-shadow: none;
    --color: var(--bs-primary);

    text-transform: none;
    font-weight: 400;
    max-width: none;
    margin: 0;
    text-decoration: underline;
}

ion-button.modal-request__req.inactive{
    opacity: .5;
    --color: var(--ion-text-color);
    cursor: not-allowed;
}