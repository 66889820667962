ion-toast.custom-toast{
    --color: var(--bs-primary);
    --box-shadow: 0 0 10px hsla(190, 49%, 33%, 0.025);
    --border: 1px solid var(--c-border);
    --background: white;
    text-align: center;
}

ion-toolbar{
   --background: var(--c-white);
   --border-color: var(--c-border);
   --padding-end: 1.25rem;
   border-bottom: 1px solid var(--border-color);
   box-shadow: 0 0 5px #2b6f7d0e;
}

.header-md, .header-ios{
    box-shadow: none;
}

.header-md::after, .header-ios::after{
    background-image: none;
}

ion-content.login-content{
    --ion-background-color: var(--bs-primary-hover);
}

.login{
    margin: 3.5rem 5%;
    
}

.login__head{
    border-radius: .5rem .5rem 0 0;
    padding: 1.5rem 1rem;
    background-color: var(--bs-primary); 

    max-width: 25rem;
    margin: 0 auto;
}

.login__title{
    font-size: 1.5rem;
    text-transform: uppercase;
    text-align: center;
    font-weight: 600;

    color: var(--c-white);
}

.login__form{
    margin: auto;
    max-width: 25rem;
    padding: 1.25rem;

    background-color: var(--c-white);
    border: 1px solid var(--c-border);
    border-radius: 0 0 .5rem .5rem;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.025);
}

.login__input:focus ~ .login__label, .login__label.active, input:-internal-autofill-selected + .login__label{
    font-size: .9rem;
    color: #3a3a3a;
    top: -0.5rem;
    background-color: white;
}

ion-note{
    text-align: center;
    width: 100%;
    margin-top: 1rem;
}

ion-note a {
    cursor: pointer;
    opacity: .75;
    color: var(--bs-primary);
}

ion-note a:hover{
    opacity: 1;
}