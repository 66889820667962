.details-notif{
    .request__button{
        width: 100%;
    }

    &__data{
        font-size: 1rem;

        span{
            font-weight: bold;
            color: var(--bs-primary);
            margin-right: .5rem;
            
            display: block;
        }
    }
}