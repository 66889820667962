.notifs{
    padding: var(--padding);
    padding-bottom: 5rem;
    color: #1a1a1a;
    min-height: calc(100vh - 56.59px);
}

.notifs__tabs{
    list-style: none;
    margin: 0;
    padding: 0;

    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: .75rem;
    
    border-bottom: 1px solid var(--bs-primary-t-025);
    margin-bottom: 1rem;
    padding: 0 .5rem;
}

.notifs__tab{
    width: 100%;
}

.notifs__tab-button{
    width: 100%;
    color: var(--bs-primary);
    background-color: #2b717f26;

    font-size: .95rem;
    text-transform: uppercase;
    padding: .75rem 1rem;

    border-radius: .25rem .25rem 0 0;
    /* border: 1px solid var(--bs-primary-t-025); */
    border-bottom: none;
    transition: all .3s ease;
}

.notifs__tab:not(.active):hover .notifs__tab-button{
    background-color: #2b717f34;
}

.active .notifs__tab-button{
    color: white;
    background-color: var(--bs-primary);
}

.notifs__column{
    display: none;
}

.notifs__column.active{
    display: block;
}

.notifs__head{
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    /* display: grid; */
    /* grid-template-columns: repeat(3, 1fr); */

    margin-bottom: 1rem;

    border-bottom: 1px solid var(--bs-primary-t-025);
    /* padding-bottom: 1rem; */
    border-radius: 0;
}

.notifs__title{
    margin: 0 calc(var(--section-padding) / 2) var(--section-padding);
    padding: 0;
    font-size: .95rem;

    flex-grow: 1;

    display: flex;
    justify-content: center;
    align-items: center;
    
    border-radius: .25rem;
    background-color: var(--c-notifs-bkg, #2b717f26);
    color: var(--c-notifs-title, var(--bs-primary));

    transition: all .3s ease;
}

.notifs__title.active{
    background-color: var(--c-notifs-title, var(--bs-primary));
    color: rgba(255, 255, 255, 0.9);
}

.notifs__title.accepted, .notifs__title.received{
    --c-notifs-bkg: var(--color-green-t-015);
    --c-notifs-title: var(--color-green);
}

.notifs__title.denied{
    --c-notifs-bkg: var(--bs-secondary-t-015);
    --c-notifs-title: var(--bs-secondary);
}

.notifs__title-text{
    text-transform: uppercase;
    padding: .5rem 1rem;
    flex-grow: 1;
    text-align: center;
    pointer-events: none;
}

.notifs__title-num{
    padding: .5rem .75rem;
    border-left: 1px solid var(--c-notifs-title, var(--bs-primary));
    border-radius: 0 .25rem .25rem 0;
    pointer-events: none;
}

.notifs__title.active .notifs__title-num{
    border-color: rgba(255, 255, 255, 0.5);
}

.notifs__body{
    padding: .75rem var(--section-padding);
    border-radius: .5rem;
    background-color: var(--bs-primary-selected);
    
    display: grid;
    grid-template-columns: 1fr;
    gap: .75rem;
    grid-auto-flow: row dense;
}

.notif{
    width: 100%;
    padding: .75rem;
    /* padding: .5rem .5rem .5rem .75rem; */
    /* margin-bottom: 1rem; */
    border-radius: .5rem;
    background-color: white;
    border: 1px solid var(--c-border);
    box-shadow: 0 0 10px hsla(190, 49%, 33%, 0.025);

    width: 100%;
    border-left: .5rem solid pink;
}

.notif.inactive{
    display: none;
}

.notif.pending, .notif.sent{
    border-left-color: var(--bs-primary);
    order: 0;
}

.notif.accepted, .notif.received{
    border-left-color: var(--color-green);
    order: 1;
}

.notif.denied{
    border-left-color: var(--bs-secondary);
    order: 2;
}

.accepted{
}

.notif__head{
    
}

.notif__date{
    margin:0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    padding: 0 .25rem .75rem;
    /* margin-bottom: 1rem; */
    color: var(--bs-primary-medium);

    /* border-bottom: 1px solid var(--c-border); */
    margin-bottom: 1rem;
    border-bottom: 1px solid var(--c-border);
}
/* 
.notif__date:last-child{
    margin-bottom: 0;
    margin-left: 1rem;
    border-bottom: none;
} */

.notif__date span > span + span{
    margin-left: .5rem;
}

.notif__date ion-icon{
    margin-right: .25rem;
    vertical-align: text-top;
    margin-top: .75px;
}

.notif__title{
    font-size: 1rem;
    padding: 0 0 .5rem .25rem;
    margin-bottom: .75rem;
    border-bottom: 1px solid var(--c-border);
    font-weight: bold;
    color: #383838;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}

.notif__title-text{
    margin:0;
    order:1;
    width: 100%;
    /* align-self: flex-end; */
}

.notif__title-text span{
    display: block;
    font-size: .95rem;
    font-weight: normal;
    color: var(--bs-primary-medium);
    margin: .25rem 0 .15rem;
}

.notif__refused{
    font-size: .9rem;
    font-weight: normal;
    color: var(--bs-primary-medium);
    margin: 0.25rem .5rem 0.15rem 0;
}

.notif__title ion-buttons{
    order:0;
}

.notif__title ion-button{
    --padding-start: .15rem;
    --padding-end: .15rem;
}

.notif__title ion-button svg{
    width: 100%;
    height: 100%;
}

.notif__body{
}

.notif__props{
    padding-bottom: .75rem;
}

.notif__prop{
    line-height: 1.5;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;

    color:#383838;

    padding: 0 .25rem;
}

.notif__prop ion-icon{
    align-self: center;
    margin-right: .25rem;
    color: var(--bs-primary);
}

.notif__prop:nth-child(1n + 2){
    margin-top: .5rem;
}

.notif__buttons{
    margin-bottom: .25rem;
}

.notif__button{
    padding: .5rem;
    border-radius: .5rem;
    text-align: center;

    margin-bottom: 1rem;
}

.notif.accepted .notif__button{
    background: var(--color-green);
    color: white;
}

.notif.denied .notif__button{
    background: var(--bs-secondary);
    color: white;
}

.notif.pending .notif__button{
    background: var(--bs-primary);
    color: white;
}

.notifs__svg{
    width: 1rem;
    height: 1rem;
    margin: .23em .75rem 0 .5rem;
    color: var(--bs-primary);
    flex-shrink: 0;
}

.notif__p{
    margin:0;
    padding:0;
}

.notif__span{
    display: block;
    font-weight: bold;
    margin-right: .5rem;
    /* color: var(--bs-primary); */
}

.notifs__body .sc-ion-buttons-ios-h, .notifs__body .sc-ion-buttons-md-h{
    justify-content: center;
    /* margin-top: .35rem; */
    /* border-top: 1px solid var(--c-border); */
    padding: 0;
    flex-grow: 1;
}

.request__button.request__button{
    height: 2rem;
    margin: 0;
    border-radius: .25rem;
    color: var(--color-green);
    background-color: var(--color-green-t-015);
    --background: transparent;
    --box-shadow: none;

    display: flex;
    justify-content: center;
    align-items: center;

    flex-grow: 1;
    width: 40%;
    margin-bottom: .5rem;
}   

.request__button.button-disabled{
    opacity: 1;
}

.request__button.cancel{
    background-color: var(--bs-secondary-t-015);
    color: var(--bs-secondary);
}

.request__button.icon{
    background-color: #2b717f26;
    color: var(--bs-primary);
}

.request__button:nth-child(1n + 2){
    margin-left: .5rem;
}

.request__button:first-of-type{
    margin-left: 0;
}

.sc-ion-alert-md-h{
    --max-width: 550px;
}

.notif__open{
    text-transform: uppercase;
    margin-top: 1rem;
    padding: .75rem;
    border-radius: .25rem;
    border: 1px solid var(--bs-primary-t-025);
    width: 100%;

    color: var(--bs-primary);
    background-color: var(--bs-primary-selected);
}

.notif__open:hover{
    background-color: var(--bs-primary-selected-hover);
}

/* .notif__create{
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    left: 1rem;
} */

@media screen and (min-width: 850px){
    .notif__title{
        font-size: 1.15rem;
        flex-wrap: nowrap;
    }

    .notif__title-text{
        width: auto;
    }

    .notifs__body .sc-ion-buttons-ios-h, .notifs__body .sc-ion-buttons-md-h{
        flex-grow: 0;
    }

    /* .notif__create{
        bottom: 2rem;
        right:2rem;
    } */
}

@media screen and (min-width: 850px) {
    .notifs__title{
        max-width: 200px;
    }

    .notifs__body{
        grid-template-columns: repeat(2, 1fr);
    }

    .notif__title-text{
        flex-basis: 80%;
        order:0;
    }
    
    .notif__title ion-buttons{
        order:1;
    }

    .request__button{
        margin: 0;
    }

    .request__button:first-of-type{
        margin-left: 0;
        flex-grow: 0;
        width: 2rem;
    }
}

@media screen and (min-width: 1700px){
    .notifs__head{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }

    .notifs__body{
        grid-template-columns: repeat(3, 1fr);
    }

    .notifs__column--two .notifs__head, .notifs__column--two .notifs__body{
        grid-template-columns: repeat(2, 1fr);
    }

    .notifs__title{
        max-width: none;
    }

    .notif.pending, .notif.sent{
        grid-column: 1;
    }
    
    .notif.accepted, .notif.received{
        grid-column: 2;
    }
    
    .notif.denied{
        grid-column: 3;
    }
}

/* .notif.accepted{
    order: 1;
}

.notif.denied{
    order: 2;
}

.notif.pending{
    order: 0;
} */



/* *{
    transition: .2s ease all;
} */

/* @media screen and (max-width: 700px){
    .notifs__title{
        margin: 0 .25rem
    }
}

@media screen and (max-width: 650px){
    .notifs__head{
        flex-direction: column;
    }
    .notifs__title{
        margin: 0 .25rem;
        width: 100%;
        margin-bottom: .25rem;
        text-align: center;
    }
}

@media screen and (max-width: 490px){
    .notif__head{
        flex-direction: column;
        align-items: revert;
        margin-bottom: .5rem;
    }
    .notif__button{
        border-radius: .5rem;
    }
} */