.profile_picture{

}
.profile_picture__title{
    display: flex;
    flex-flow: column nowrap;
    
}
.profile_picture__input{
    border: none;
    appearance: none;
    padding: .5rem;
    border: 1px solid var(--bs-primary);
    border-radius: .5rem;
}
.profile_picture__button.request__button{
    background-color: var(--bs-primary);
    color: white;
    border-radius: .5rem;
    margin-top: .5rem;
    padding: .5rem;
}
.profile_picture__button.inactive{
    background: #d7d7d7;
    cursor: not-allowed;
}
