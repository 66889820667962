.not-found{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    height: 100%;
    overflow: auto;
    background-color: var(--bs-primary-selected);
    
    &__icon{
        width: 8rem;
        height: 8rem;
        color: var(--bs-primary);

        @media screen and ( max-width : 650px ) {
            width: 4rem;
            height: 4rem;
        }
    }

    &__error{
        text-align: center;
        font-size: 4rem;
        margin: 0;

        @media screen and ( max-width : 650px ) {
            font-size: 2.5rem;
        }

        span{
            display: block;
            font-size: 2rem;
            color: var(--bs-primary-medium);

            @media screen and ( max-width : 650px ) {
                font-size: 1.5rem;
            }
        }
    }
}